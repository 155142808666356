import * as React from "react";
import Layout from "../components/layout";
import CommunityContent from "../components/community/communityContent";
import BannerImage from "../components/master/BannerImage";
import heroImage from "../assets/community/community-hero-image.jpg";
const OurCommunityPage = () => {
  return (
    <Layout>
      <BannerImage imagePath={heroImage} altText="..." />
      <CommunityContent />
    </Layout>
  );
};

export default OurCommunityPage;
