import React from "react";
import ContentWrapper from "../master/ContentWrapper";
import PageTitle from "../master/PageTittle";
import {Link} from "gatsby";
import SideBar from "../master/SideBar";
import {community} from "../const";

const CommunityContent = () => (
    <div className="container">
        <ContentWrapper sidebar={true} narrow={true}>
            <div className={"content"}>
                <PageTitle title="Community"/>


                <p>
                    The St Maroun’s Parents Association (PA) main objective is to generate goodwill and support for the
                    school, by involving parents and friends in a variety of ways.
                </p>

                <p>
                    The PA organise social activities for students and parents that raise funds and enrich the lives of
                    our
                    students and enhance their education.
                </p>

                <p>
                    Funds raised go directly towards educational resources and targeted projects.
                </p>

                <p>
                    All parents of current St Maroun’s students are automatically provided with membership to the PA and
                    are
                    encouraged to become actively involved.
                </p>

                <PageTitle title="Meetings" variant="h3"/>

                <p>
                    The PA meets at the College during school term. Notifications for meetings are sent via email and
                    the College portal. All parents and friends are welcome.
                </p>
                <PageTitle title="Events" variant="h3"/>

                <p>
                    The PA host a number of events that focus on student activities throughout the year, upcoming events
                    are on our College calendar.
                </p>

                <p>
                    Some of these events include:
                </p>

                <ul>
                    <li>Annual College Fete</li>
                    <li>BBQ’s</li>
                    <li>Mother’s Day and Father’s Day Stalls</li>
                </ul>

                <p>
                    The Committee consists of a group of parent’s and friend’s that devote their time generously for the
                    benefit of the school community.
                </p>


                <PageTitle title="Contact Us" variant="h3"/>

                <p>
                    For more information or to find out how you can get involved please
                    email{" "}
                    <a href="mail:pasmc@stmarouns.nsw.edu.au">
                        pasmc@stmarouns.nsw.edu.au
                    </a>{" "}
                    or <Link to="/contact">Contact Us</Link>.
                </p>
            </div>
            {/* <SideBar items={community} title="Our Community" /> */}
        </ContentWrapper>
    </div>
);

export default CommunityContent;
